// Generated by Framer (334e254)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Feather } from "https://framerusercontent.com/modules/f0DboytQenYh21kfme7W/zb1zVBMZJKgPMiedOi0y/Feather.js";
const FeatherFonts = getFonts(Feather);

const cycleOrder = ["pZ3dqmjUb", "ETNCp44WN"];

const serializationHash = "framer-WRDOI"

const variantClassNames = {ETNCp44WN: "framer-v-1lsipti", pZ3dqmjUb: "framer-v-zn8wb6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Humburger: "pZ3dqmjUb", x: "ETNCp44WN"}

const getProps = ({color, height, id, tap, width, ...props}) => { return {...props, njNJdZBy3: tap ?? props.njNJdZBy3, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "pZ3dqmjUb", vJH2yQXFN: color ?? props.vJH2yQXFN ?? "var(--token-c14065e7-5a4d-4109-85cf-bca8f791260a, rgb(255, 255, 255)) /* {\"name\":\"Neutral 01\"} */"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, njNJdZBy3, vJH2yQXFN, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "pZ3dqmjUb", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1vty52i = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (njNJdZBy3) {
const res = await njNJdZBy3(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-zn8wb6", className, classNames)} data-framer-name={"Humburger"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"pZ3dqmjUb"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap1vty52i} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({ETNCp44WN: {"data-framer-name": "x"}}, baseVariant, gestureVariant)}><motion.div className={"framer-obyckm-container"} layoutDependency={layoutDependency} layoutId={"RXfrchk_P-container"}><Feather color={vJH2yQXFN} height={"100%"} iconSearch={"Home"} iconSelection={"menu"} id={"RXfrchk_P"} layoutId={"RXfrchk_P"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({ETNCp44WN: {iconSelection: "x"}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-WRDOI[data-border=\"true\"]::after, .framer-WRDOI [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-WRDOI.framer-bfd983, .framer-WRDOI .framer-bfd983 { display: block; }", ".framer-WRDOI.framer-zn8wb6 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 30px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 30px; }", ".framer-WRDOI .framer-obyckm-container { flex: 1 0 0px; height: 100%; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-WRDOI.framer-zn8wb6 { gap: 0px; } .framer-WRDOI.framer-zn8wb6 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-WRDOI.framer-zn8wb6 > :first-child { margin-left: 0px; } .framer-WRDOI.framer-zn8wb6 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 30
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ETNCp44WN":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"njNJdZBy3":"tap","vJH2yQXFN":"color"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerQ4PkO0rzL: React.ComponentType<Props> = withCSS(Component, css, "framer-WRDOI") as typeof Component;
export default FramerQ4PkO0rzL;

FramerQ4PkO0rzL.displayName = "Humburger";

FramerQ4PkO0rzL.defaultProps = {height: 30, width: 30};

addPropertyControls(FramerQ4PkO0rzL, {variant: {options: ["pZ3dqmjUb", "ETNCp44WN"], optionTitles: ["Humburger", "x"], title: "Variant", type: ControlType.Enum}, njNJdZBy3: {title: "Tap", type: ControlType.EventHandler}, vJH2yQXFN: {defaultValue: "var(--token-c14065e7-5a4d-4109-85cf-bca8f791260a, rgb(255, 255, 255)) /* {\"name\":\"Neutral 01\"} */", title: "Color", type: ControlType.Color}} as any)

addFonts(FramerQ4PkO0rzL, [...FeatherFonts])